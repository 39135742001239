<template>
    <div>Test
        <vx-card>
            <vue-bootstrap4-table :rows="data" :columns="columns" :config="config" :actions="actions">
                <template slot="row_actions" slot-scope="props">
                  <vx-tooltip text="Bearbeiten" class="w-1" position="left">
                    <vs-button  color="warning" type="filled" icon="edit" size="small"
                               :to="'/team/'+props.row.id"></vs-button>
                  </vx-tooltip>
                </template>
                <template slot="member_count" slot-scope="props">
                    <span class="vbt-table-td">
                      <b>
                        {{props.cell_value.length}}
                      </b>
                    </span>
                </template>
                <template slot="empty-results">
                    Keine Teams gefunden.
                </template>
            </vue-bootstrap4-table>
        </vx-card>
    </div>
</template>

<script>
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

    import ApiService from "../../../api";

    import moment from 'moment';

    export default {
        components: {
            VueBootstrap4Table
        },
        data() {
            return {
                data: [],
                actions: [
                    {
                        btn_text: "Hinzufügen",
                        router_link: '/teams/add',
                        color: 'success'
                    },
                    {
                        btn_text: "CSV Export",
                        event_name: "on-export-csv",
                    }
                    ,
                    {
                        btn_text: "Tabellen Spalten",
                        event_name: "on-columns",
                    }
                ],
                columns: [
                    {
                        label: "ID",
                        name: "id",
                        filter: {
                            type: "simple",
                            placeholder: "ID suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    },
                    {
                        label: "Name",
                        name: "name",
                        filter: {
                            type: "simple",
                            placeholder: "Name suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    }, {
                        label: "Mitglieder",
                        name: "users",
                        slot_name: "member_count",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    }, {
                        label: "Workspace",
                        name: "workspace.unique_name",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    }, {
                        label: "Actions",
                        slot_name: "row_actions",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'actions-column'
                    }],
                config: {
                    checkbox_rows: false,
                    rows_selectable: true,
                    page: 1,
                    per_page: 10,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8'
                }

            }
        },
        created() {
            this.fetchData()
        },
        computed: {},
        methods: {
            dateFilterValidator(column, value) {
            },
            fetchData() {
                this.$vs.loading()
                ApiService.get('teams').then(response => {
                    this.data = response.data.result;
                    this.$vs.loading.close()
                }).catch(response => {
                })
            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            }
        },
        mounted() {
        }
    }

</script>
